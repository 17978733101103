.dropdown {
  @mixin arrow($size, $color) {
    border: $size solid transparent;
    border-bottom-color: color($color);
  }
  color: color(black);
  $arrow-size: 5px;
  $item-vertical-padding: .6em;
  position: relative;
  cursor: pointer;
  display: inline-block;
  &__block {
    width: 100%;
  }

  &_content {
    background: color(light);
    box-shadow: 0 0 10px transparentize(color(dark), .8);
    text-align: left;
    margin-top: $arrow-size;
    max-height: 6 * ($item-vertical-padding * 2 + $base-line-height);
    overflow: auto;
    transition: $transition;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: calc(50% + #{$arrow-size*4});
    max-width: 500px;
    z-index: 200;
    border-radius: 8px;

    &.center {
      left: 50%;
      transform: translate(-50%);
    }

    &.right {
      right: 0;
      left: auto;
      transform: none;
    }
  }

  &_item {
    display: block;
    padding: $item-vertical-padding $spacing;
    font-weight: 700;
    white-space: nowrap;
    user-select: none;
    transition: $transition;
    line-height: $base-line-height;
    @extend .t-ellipsis;

    &:hover {
      background: color(grey-lighter);
    }
  }
}
