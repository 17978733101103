.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;

  &__row {
    @include breakpoint-max(lg) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &:not(:first-of-type):not(:last-of-type) {
        margin: 0.5em 0;
      }
    }
  }

  &__cell,
  &__title {
    @include breakpoint-max(lg) {
      flex: 1 1 150px;
    }

    vertical-align: middle;
    text-align: left;
    padding: 5px 10px;
  }

  &__cell--empty {
    flex: 1 0 90%;
    text-align: center;
  }

  &__body,
  &__header {
    background: color(light);
  }

  &__striped tbody tr:nth-of-type(odd),
  &__striped tbody tr:nth-of-type(odd) th {
    background: color(light);
  }

  &__bordered,
  &__bordered td,
  &__bordered th {
    border: 1px solid color(grey-lighter);
  }
}
