.link {
  font-weight: 700;
  background: none;
  border: none;
  padding: 0;
  color: color(main-dark);
  outline: none;
  cursor: pointer;

  &--header {
    font-weight: 700;
  }

  &--dark {
    color: color(blue-dark);
  }

  &--secondary {
    color: color(grey-dark);
  }

  &--black {
    color: color(black);
  }

  &--light {
    color: color(light);
  }

  &--active {
    font-weight: 700;
    position: relative;
    color: #198c6b;
    &:before {
      background: rgba(17,21,23,0.1);
    }
  }
}