$amounts: (
  quarter: $spacing / 4,
  half: $spacing / 2,
  normal: $spacing,
  sesqui: $spacing * 1.5,
  double: $spacing * 2,
  square: $spacing * 4,
  none: 0
);

$directions: (top, bottom, left, right, all, vertical, horizontal);

@function get-name($name, $type) {
  @if $name == all or $name == normal {
    @return "";
  } @else {
    @if $type == "direction" {
      @return str-slice($name, 0, 1);
    } @else {
      @return "-" + $name;
    }
  }
}

@mixin spacing($property, $direction, $amount) {
  @if $direction == vertical {
    #{$property}-top: $amount;
    #{$property}-bottom: $amount;
  } @else if $direction == horizontal {
    #{$property}-left: $amount;
    #{$property}-right: $amount;
  } @else if $direction == all {
    #{$property}: $amount;
  } @else {
    #{$property}-#{$direction}: $amount;
  }
}

@each $direction in $directions {
  @each $amountname, $amount in $amounts {
    .m#{get-name($direction, "direction")}#{get-name($amountname, 'amount')} {
      @include spacing(margin, $direction, $amount);
    }
    .p#{get-name($direction, "direction")}#{get-name($amountname, 'amount')} {
      @include spacing(padding, $direction, $amount);
    }
  }
  @each $breakpointname, $breakpoint in $breakpoints {
    @each $amountname, $amount in $amounts {
      .m#{get-name($direction, "direction")}#{get-name($amountname, 'amount')}-#{$breakpointname} {
        @media screen and (min-width: $breakpoint) {
          @include spacing(margin, $direction, $amount);
        }
      }
      .p#{get-name($direction, "direction")}#{get-name($amountname, 'amount')}-#{$breakpointname} {
        @media screen and (min-width: $breakpoint) {
          @include spacing(padding, $direction, $amount);
        }
      }
    }
  }
}

.m-auto {
  margin: auto;
}

.mh-auto {
  margin: 0 auto;
}

.p-menu {
  margin-left: 250px;

  @media (max-width: $md-lower) {
    margin-left: 0;
  }

  .banner ~ section & {
    margin-top: $banner-height;
    @include breakpoint(lg) {
      margin-top: $banner-height;
    }
  }
}

.p-menu-settings {
  margin-left: 220px;

  @media (max-width: $md-lower) {
    margin-left: 0;
  }
}
