$first-column-width: 260px;
$total-column-width: 50px;

.timesheet {
  .to_correct .dts,
  .to_submit .dts {
    cursor: pointer;
  }

  .dts.company {
    cursor: default;
  }

  .approved .dts {
    cursor: default;
  }

  .approved .dts {
    cursor: default;
  }

  .month-selector {
    width: $first-column-width;
    @media (max-width: $md) {
      width: 100%;
    }

    .current-month {
      font-weight: bold;
    }

    .switch-month-btn {
      padding: 10px;
      font-size: 1.5em;
      color: color(blue-grey);
      cursor: pointer;

      &:hover {
        color: color(blue-dark);
      }
    }
  }

  .days-in-month {
    @media (max-width: $md) {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .table-container {
    width: calc(100% - #{$first-column-width});
    margin: 0 auto 0 $first-column-width;
    overflow-x: scroll;
    overflow-y: hidden;
    @media (max-width: $md) {
      width: 100%;
      margin: 0;
    }

    table {
      border-collapse: collapse;

      tbody tr,
      thead {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;
      }

      tbody tr th,
      thead th:first-child {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: $first-column-width;
        height: 52px;
        padding: 0 $spacing;
        margin-left: -$first-column-width;
        margin-top: -1px;
        @media (max-width: $md) {
          display: table-cell;
          position: relative;
          margin-left: 0;

          div {
            display: flex;
            width: 100%;
            align-items: center;
            height: 100%;
          }
        }
      }

      .with-overtime th {
        height: 74px;
      }

      thead {
        border-bottom: 1px solid color(grey);
        background-color: color(white);

        th {
          height: 50px;
          min-width: 50px;

          &:first-child {
            z-index: 1;
            justify-content: center;
            background-color: color(white);
            border-bottom: 1px solid color(grey);
          }

          &:not(:first-child) {
            border-right: 1px solid color(grey);
          }
        }
      }

      tbody {
        user-select: none;

        tr {
          &.mission td {
            border-right: 1px solid color(grey);
          }

          th {
            text-align: left;
            user-select: text;
            font-weight: 200;
          }

          td {
            min-height: 50px;
            min-width: 50px;
            padding: 10px 5px;

            .approved & {
              cursor: default !important;
              background: red;
            }

            .approved & {
              cursor: default !important;
              background: red;
            }

            &.with-overtime {
              min-height: 65px;
            }

            &:first-child {
              margin-left: $first-column-width;
            }

            &.selected {
              background: color(blue-king-light);
            }

            &.disabled {
              background: repeating-linear-gradient(
                45deg,
                color(grey-light),
                color(grey-light) 10px,
                lighten(color(grey-dark), 50) 10px,
                lighten(color(grey-dark), 50) 20px
              );
              cursor: inherit;
              pointer-events: none;
            }
          }

          .hours-container {
            display: flex;
            flex-direction: column;
            height: 100%;
          }
        }

        .mission,
        .mission th {
          z-index: 1;
          border-top: 1px solid darken(color(blue-light), 10);
        }

        .mission__title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 $spacing;
          color: color(blue-grey);
        }

        .mission th:last-of-type {
          @media (max-width: $md) {
            border-bottom: 0;
          }
        }
      }

      tbody .total,
      thead th.total {
        cursor: default;
        width: $total-column-width;
        min-width: inherit;
        background: color(blue-light);
        border-left: 1px solid darken(color(blue-light), 10);
        border-right: 1px solid darken(color(blue-light), 10);
      }

      tbody .total {
        padding-top: 15px;
        text-align: center;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .status {
        padding: 5px 10px;
        text-transform: uppercase;
        font-weight: 900;
        letter-spacing: 0.2px;
        display: inline-block;

        &.to_submit {
          color: color(grey);
          background: transparentize(color(grey), 0.9);
        }

        &.to_correct {
          color: color(orange);
          background: transparentize(color(orange), 0.9);
        }

        &.to_approve {
          color: color(blue-grey);
          background: transparentize(color(blue-grey), 0.9);
        }

        &.approved {
          color: color(main-dark);
          background: transparentize(color(main-dark), 0.9);
        }
      }

      .day {
        font-weight: 200;
      }

      .hours {
        font-weight: 700;
        display: block;
        padding: 5px;
        text-align: center;
        background: lighten(color(blue-grey), 50);
        border-radius: 5px;
      }

      .overtime,
      .overtime-hours {
        font-size: 0.7em;
        font-weight: 700;
        text-transform: capitalize;
        color: color(blue-king);
      }

      .overtime-hours {
        display: block;
        margin: 5px 0 0;
        text-align: center;
        height: 17px;
        line-height: 15px;
        background: lighten(color(blue-king), 30);
        border: 1px solid color(blue-king);
        border-radius: 20px;
      }
    }
    @media (max-width: $md) {
      table {
        border-left: 1px solid darken(color(blue-light), 10);
        border-right: 1px solid darken(color(blue-light), 10);

        thead th {
          border-top: 1px solid darken(color(blue-light), 10);

          &:first-child {
            box-shadow: inset 0 -1px 0 0 darken(color(blue-light), 10);
          }
        }

        tbody tr.mission td:last-child {
          border-right: none;
        }
      }
    }
  }
}
