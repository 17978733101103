// Contact list item

.chatcontact {
  display: flex;
  width: 100%;
  padding: 15px 10px 15px 25px;
  border-bottom: solid 1px color(grey-lighter);
  position: relative;
  cursor: pointer;

  &.active:before {
    content: "";
    background: #F1F1F1;
    z-index:  0;
    position: absolute;
    top: $spacing / 4;
    left: $spacing / 4;
    width: calc(100% - #{$spacing / 2});
    height: calc(100% - #{$spacing / 2});
    border-radius: $border-radius * 2;
  }

  &.unread {
    &:before {
      content: "";
      position: absolute;
      left: 8px;
      width: 10px;
      height: 10px;
      background: color(main);
      top: 50%;
      border-radius: 50%;
      transform: translateY(-50%);
    }
  }

  &__main {
    padding: 0 5px 0 10px;
    overflow: hidden;
    white-space: nowrap;
    flex: 1;
    z-index: 1;
  }

  &__header {
    display: flex;
  }

  &__name {
    font-weight: 400;
    flex: 1;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    .unread & {
      font-weight: 700;
    }
  }

  &__date {
    font-weight: 600;
    text-align: right;
    margin-left: 5px;
    white-space: nowrap;
    color: color(grey-dark);
    font-size: 0.8em;

    .unread & {
      color: color(main-dark);
      font-weight: 800;
    }
  }

  &__subject {
    color: color(grey-dark);
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9em;

    .unread & {
      color: color(dark);
      font-weight: 700;
    }
  }
}
