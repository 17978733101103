.cursor {
  &-p {
    cursor: pointer;
  }
}

.scrollover {
  &-x {
    overflow-x: scroll;
  }

  &-y {
    overflow-y: scroll;
  }
}

.br-word {
  word-break: break-word;
}

.radius {
  border-radius: 3px;
}

.no-flex {
  display: block;
}

.d-inline-block {
  display: inline-block;
}


.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

// .content-with-menu {
//   margin-left: 220px;
//   width: calc(100vw - 220px);
//   max-width:  $xl;
//   padding: 32px;
//   @media (min-width: $xl) {
//     margin: 0 auto;
//     padding-left: 252px;
//     min-width: $xl;
//   }
//   @media (max-width: $md-lower) {
//     width: 100vw;
//     margin-left: 0;
//   }

//   .banner ~ section & {
//     margin-top: $banner-height;
//     @include breakpoint(lg) {
//       margin-top: $banner-height;
//     }
//   }
// }

// .content-without-menu {
//   margin: 0 auto;
//   max-width: 1280px;
// }

// .p-menu-settings {
//   margin-left: 220px;
//   @media (max-width: $md-lower) {
//     margin-left: 0;
//   }
// }