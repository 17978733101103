%resetInput {
  outline: none;
  border: none;
  border-radius: 0;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.input {
  $height: 1.8em;
  $border-width: 1px;
  $easing: cubic-bezier(0.165, 0.84, 0.44, 1);
  font-size: rem(size(md));
  margin-bottom: $spacing * 1.5;
  display: block;
  color: color(blue-dark);
  position: relative;

  &--simplesearch {
    width: 100%;
    padding: 10px 10px 10px 30px;
    margin: 0 auto 10px;
    outline: none;
    border: none;
    border-radius: 3px;
    position: relative;
    color: color(dark);
    border: 1px solid color(grey-light);
  }

  &--hidden {
    display: none;
  }

  &--fullwidth {
    width: 100%;
  }

  .error {
    animation: errorApparition 0.6s $easing;
    position: absolute;
    top: 100%;
  }

  &_content {
    position: relative;
    display: flex;
    flex-direction: column-reverse;

    &:before {
      @extend .icon;
      position: absolute;
      right: 0;
      bottom: 0.5em;
    }
  }

  &_box {
    @extend %resetInput;
    font-size: 1em;
    line-height: $height;
    height: $height;
    font-weight: 700;
    width: 100%;
    color: color(blue-dark);
    transition: $transition;
    padding: 8px 16px;
    background: #ffffff;
    border: 1px solid #f1f1f1;
    display: block;
    height: 40px;
    border-radius: 8px;

    max-width: 100%;
    &.loading {
      .hasicon & {
        padding-right: 2.7em;
      }
      padding-right: 1.5em;
    }

    .hasicon & {
      padding-right: 1.2em;
    }

    &:focus {
      outline: none;
      border-bottom-color: color(main);

      & + .label {
        color: color(main-dark);
      }
    }

    &:disabled,
    &.disabled {
      opacity: 0.5;

      & + .label {
        opacity: 0.5;
      }
    }
  }

  &_area {
    height: $height * 3;
    padding: 0;
    resize: none;
  }

  &_loader {
    position: absolute;
    right: 2em;
    bottom: 2.7em;
    font-size: calc(0.25em);

    .hasicon & {
      right: 7em;
    }
  }

  &_percentage:before {
    content: "%";
    position: absolute;
    right: 0;
    bottom: 5px;
  }

  &_charactercount {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    font-weight: 700;
    font-size: 0.9em;
    text-align: right;
  }

  &_helper {
    float: right;
    margin-top: -35px;
  }
}

::placeholder {
  color: color(grey-dark);
  font-weight: 400;
  opacity: 1;
}
@keyframes errorApparition {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
