$picSize: 45px;
$picSizeLg: $picSize * 3;
$iconSize: 25px;
$iconSizeLg: $iconSize * 1.5;

.avatar {
  overflow: hidden;
  position: relative;
  display: inline-block;
  border-radius: 50%;
  height: $picSize;
  width: $picSize;
  min-height: $picSize;
  min-width: $picSize;
  border: 1px solid color(grey);

  &_lg {
    height: $picSizeLg;
    width: $picSizeLg;
    min-height: $picSizeLg;
    min-width: $picSizeLg;
  }

  img {
    width: 100%;
    margin: 0 auto;
  }
}

.userpic {
  border-radius: 50%;
  height: $picSize;
  width: $picSize;
  overflow: hidden;
  position: relative;
  text-align: center;
  font-size: 0.6em;
  background-color: color(grey-lighter);
  @extend .icon;
  @extend .icon-user;

  &--shadowed {
    box-shadow: 0 3px 10px 0 #c8cdd5;
  }

  &:before {
    font-size: $iconSize;
    line-height: $picSize;
  }

  &_lg {
    height: $picSizeLg;
    width: $picSizeLg;

    &:before {
      font-size: $iconSizeLg;
      line-height: $picSizeLg;
    }
  }

  &_add {
    @extend .icon-user-plus;
  }

  &_upload {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }
}
