.big-choice {
  transition: all $time $easing;
  padding: 50px 0;
  width: 50%;
  vertical-align: top;
  min-height: 100%;
  border-top: solid 2px color(grey-light);
  border-bottom: solid 2px color(grey-light);
  border-left: solid 1px color(grey-light);
  border-right: solid 1px color(grey-light);
  background: color(light);
  outline: none;
  cursor: pointer;

  &--left {
    text-align: left;
    padding: 30px;

  }

  &--tabs {
    border-top: solid 1px color(grey-light);
    border-bottom: solid 1px color(grey-light);
    border-left: solid 1px transparent;
    border-right: solid 1px transparent;
    background: color(light);

    .big-choice__label,
    .big-choice__title {
      color: color(grey-dark);
    }

    .big-choice__label {
      text-transform: uppercase;
      font-weight: 900;
      font-size: .8em;
      display: block;
      height: 16px;
    }

    .scrolled & {
      background: red;
    }
  }

  &--active {
    transition: all $time $easing, box-shadow .6s ease;
    box-shadow: 0 0 0 10px color(main) inset;

    .big-choice__label,
    .big-choice__title {
      color: color(black);
    }
  }

  &--scrolled {
    padding: 20px 30px;

    .big-choice__label {
      height: 0;
      opacity: 0;
    }
  }

  .icon {
    margin-bottom: 20px;
    color: color(main);
    display: inline-block;
    font-size: 2em;
  }

  &__title {
    color: color(black);
    font-size: 1.2em;
    font-weight: 900;
    @include breakpoint (sm) {
      font-size: 1.8em;
    }
  }

  &__label {
    color: color(blue-grey);
    font-weight: 700;
    transition: all $time $easing;
  }
}

// If fixed tabs 
.big-choice-fixedwrapper {
  transition: top $time $easing;
  position: fixed;
  left: 0;
  right: 0;
  top: 80px;
  z-index: 10;

  &--scrolled {
    top: 55px;
  }
  @media only screen and (max-width: 960px) {
    top: 55px;
  }
}
