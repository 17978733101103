.pagination {
  display: inline-block;
  margin-top: $spacing / 2;

  li {
    display: inline-block;

    a {
      display: block;
      padding: $spacing / 2;
      margin: $spacing / 4;
      border-radius: 3px;
      min-width: 40px;
      text-align: center;
      cursor: pointer;
      user-select: none;
    }

    &:not(.previous):not(.next):not(.break-me):not(.active) a {
      border: 1px solid color(grey);
    }

    &.active a {
      font-weight: bold;
      background-color: color(main);
      border: 1px solid color(main);
      cursor: default;
      user-select: none;
    }
  }
}
