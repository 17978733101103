@mixin font-face( $tfname, $paths: null, $weights: null, $styles: null, $exts: eot woff2 woff ttf svg ) {

  $extmods: ( eot: "?", svg: "#" + str-replace($tfname, " ", "_") );

  $formats: ( otf: "opentype", ttf: "truetype" );
  @for $i from 1 through length($paths) {
    $src: null;
    @font-face {
      font-display: swap;
      font-family: quote($tfname);
      font-weight: nth($weights, $i);
      font-style: nth($styles, $i);
      @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote(nth($paths, $i) + "." + $extmod)) format(quote($format)), comma);
      }
      src: $src;
    }
  }
}
@mixin breakpoint($breakpoint) {
   @if map-has-key($breakpoints, $breakpoint){
    @media (min-width: map-get($breakpoints, $breakpoint)) {
       @content;
    }
  }
}
@mixin breakpoint-max($breakpoint) {
   @if map-has-key($breakpoints, $breakpoint){
    @media (max-width: map-get($breakpoints, $breakpoint)) {
       @content;
    }
  }
}
@mixin capitalize() {
  &::first-letter {
    text-transform: capitalize;
  }
}
@function color($name) {
   @return map-get($colors, $name);
}
@function size($name) {
   @return map-get($sizes, $name);
}
@function rem($size) {
   @return #{$size}rem;
}
@function px($size) {
   @return #{$size*10}px;
}
@mixin underline($width, $height, $bottom, $left) {
   @extend %underline;

  &:after {
    height: $height;
    width: $width;
    bottom: $bottom;
    left: $left;
  }
}

%underline {
  position: relative;
  z-index: 10;

  &:after {
    content: '';
    background: color(main);
    display: block;
    position: absolute;
    z-index: -1;
    transition: $transition;
  }
}

@mixin mask($url) {
  -webkit-mask: url($url) no-repeat 100% 100%;
  mask: url($url) no-repeat 100% 100%;
}
