.radio {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  user-select: none;
  font-weight: 700;
  margin-bottom: 10px;

  &:hover .radio_box ~ .radio_label {
    background-color: color(grey-lightest);
  }

  &_box {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .radio_label {
      background-color: color(light);
      border: solid 2px color(main);

      &:after {
        transform: scale(1);
      }
    }
  }

  &_label {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: color(light);
    border: 2px solid color(grey-dark);
    border-radius: 50%;

    &:after {
      content: "";
      transition: transform .3s ease;
      position: absolute;
      transform: scale(0);
      display: block;
      top: 0;
      left: 0;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: color(main);
    }
  }

  &.disabled {
    opacity: .5;
    cursor: default;
  }
}

.label.disabled {
  opacity: .5;
  cursor: default;
}
