.article {
  background: color(light);
  color: color(dark);
  h1 {
    text-align: center;
    font-weight: 800;
    font-size: rem(size(xxxl));
    margin-bottom: $spacing * 4;
  }

  h2 {
    margin-bottom: $spacing * 2;
  }

  ol {
    margin: 0;
    counter-reset: item;
    padding-left: 0;
    > li > ol {
      margin-top: $spacing/2;
      padding-left: $spacing * 2;
    }
  }

  li {
    display: block;
    margin-bottom: $spacing * 2;
    font-weight: 800;
    font-size: 1.6rem;
    > ol > li {
      margin-bottom: $spacing;
      font-size: 1.7rem;
      font-weight: 500;
    }
    &:before {
      content: counters(item, ".") " ";
      counter-increment: item;
    }
  }

  p,
  li {
    line-height: 3.4rem;
  }

  b {
    font-weight: 700;
    padding: 1px 6px;
    border-radius: 3px;
    background: color(grey-light);
  }

  &__document {
    h1,
    h2 {
      background: color(grey-light);
      color: color(dark);
      border-radius: 3px;
    }
    h1 {
      text-align: center;
      font-weight: 900;
      font-size: 2rem;
      padding: 10px;
      margin-bottom: $spacing;
    }

    h2 {
      font-weight: 700;
      font-size: 1.8rem;
      padding: 4px 20px;
      margin-bottom: $spacing/2;
    }
    table {
      border: solid 1px color(grey-light);
      td {
        padding: 2px;
        border: solid 1px color(grey-light);
      }
    }
  }
}
