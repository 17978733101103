$sidebar-width: 25vw;
$content-with-sidebar-width: 70vw;
$top-spacing: $header-height;
$top-spacing-small: $header-height;

.content-with-sidebar {
  display: flex;
  flex-wrap: wrap;

  .content {
    width: 100%;

    @include breakpoint(md) {
      width: $content-with-sidebar-width;
    }
  }
}
.sidebar-nav {
  width: 100%;
  background: color(light);
  border-right: 1px solid color(grey-light);
  @include breakpoint(md) {
    width: $sidebar-width;
  }
  &__content {
    position: static;
    width: 100%;
    text-align: center;
    padding: 6rem 0;
    @include breakpoint(lg) {
      top: $top-spacing;
    }
    .scrolled + .content & {
      top: $top-spacing-small;
    }
    @include breakpoint(md) {
      min-height: 50vh;
      text-align: left;
      width: $sidebar-width;
      position: sticky;
      top: $top-spacing-small;
      padding: 3.5rem 25px 4rem 50px;
    }
  }
  &__link {
    transition: color 1s ease;
    font-size: rem(size(lg));
    font-weight: 600;
    color: color(blue-dark);
    cursor: pointer;
  }

  &__linkwrapper {
    margin: $spacing 0;
  }

  &__sections {
    margin: $spacing 0;
    padding: 0;
    @include breakpoint(md) {
      padding: 0 0 20px 30px;
    }
    .sidebar-nav__link {
      font-size: rem(size(md));
      color: color(grey-dark);
      &:before {
        transition: transform 0.5s ease;
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-20px, -50%) scale(0);
        background: color(main-dark);
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }
      @include breakpoint(md) {
        &--active {
          color: color(dark);
          position: relative;

          &:before {
            transform: translate(-20px, -50%) scale(1);
          }
        }
      }
    }
  }
}
