.geosuggest {
  &__suggests-wrapper {
    position: absolute;
    z-index: 999;
    border-radius: 8px;
    background: color(light);
  }

  &__suggests {
    padding: 8px 0;

    &-wrapper {
      box-shadow: 0 10px 40px #e0e0e0;
    }

    &--hidden {
      padding: 0;
      display: none;
    }
  }

  &__item {
    padding: 6px 16px;
    cursor: pointer;
    &:hover {
      background: #F1F1F1;
    }
  }

  &__input {
    display: block;
    border: none;
    width: 100%;
    outline: none;
  }
}
