.chatInput {
  position: relative;
  margin-top: 24px;
  
  &__wrapper {
    display: flex;
  }

  &__message {
    flex: 1;
    outline: none;
    background: #F1F1F1;
    border-radius: 16px;

    .input_box {
      border: none;
      height: auto;
      z-index: 1;
    }
  }

  &__file {
    cursor: pointer;
    & label {
      font-size: 2rem;
    }
  }
}
