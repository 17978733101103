$card-height: 120px;

.subscription {
  padding-top: $spacing;
  display: flex;
  flex-direction: column;
  @include breakpoint(md) {
    flex-direction: row;
  }

  &__item {
    transition: all .4s ease;
    padding-top: $spacing/2;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: $spacing * 2;

    &:hover {
      box-shadow: $shadow;
      background: color(light);
      transform: scale(1.02);
    }
    @include breakpoint-max(md) {
      border-bottom: 1px solid color(grey);
    }
    @each $name, $hex in $colors {
      &--#{$name} .btn {
        box-shadow: $shadow;
        background: $hex;
        @include breakpoint-max(md) {
          margin: 20px auto 10px;
          min-width: 160px;
          height: 60px;
          line-height: 60px;
        }
      }
    }

    .select-plan {
      &.cant-select {
        cursor: not-allowed;
      }
    }
  }

  &__card {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: $card-height;
    height: $card-height;
    margin: 0 10px;
    flex-direction: column;
    box-shadow: $shadow;
    cursor: pointer;
    @each $name, $hex in $colors {
      .subscription__item--#{$name}:not(.disabled) & {
        background: $hex;

        &:hover {
          background: transparentize($hex, 0.1);
        }
      }
    }
    @include breakpoint(lg) {
      padding: 30px 10px;
    }
  }

  &__cardtitle,
  &__price {
    font-size: 1.3em;
    font-weight: 800;
    margin-bottom: $spacing / 2;
    @include breakpoint(lg) {
      font-size: 1.5em;
    }
    @include breakpoint(xl) {
      font-size: 1.6em;
      margin-bottom: $spacing / 1.5;
    }
  }

  &__item.disabled {
    opacity: .5;

    .subscription__card {
      cursor: default;
    }

    .btn {
      background: color(grey);
      color: color(dark);
      cursor: default;
    }

    .subscription__cardtitle {
      color: color(grey-dark);
    }

    .subscription__price {
      color: color(grey);
    }

    .subscription__users {
      color: color(grey-dark);
    }

    .subscription__listitem {
      color: color(grey);

      &:before,
      .t-main-dark {
        color: color(grey-dark);
      }
    }
  }
}

.options,
.payments,
.selection {
  &.disabled {
    opacity: .5;
    cursor: not-allowed;

    .btn,
    .option,
    .payment {
      cursor: not-allowed;
    }
  }
}

.option,
.payment,
.selection_table {
  border: 1px solid color(grey-light);
  background: color(white);
  cursor: pointer;

  &.selected {
    background: color(main);
    color: color(white);
  }

  ul {
    list-style: inherit;
    padding: inherit;
  }
}

.selection {
  font-size: 1.5rem;
  font-weight: bold;
  cursor: default;

  &_table > div {
    padding: $spacing / 2 $spacing;
  }

  ul {
    list-style: none;
    padding-left: $spacing;
    padding-right: $spacing;

    li:before {
      @extend .transparent;
      @extend .icon;
      @extend .icon-check;
      margin-right: $spacing;
    }
  }

  .frequence {
    border-top: 1px solid color(grey);
    border-bottom: 1px solid color(grey);
  }

  .total {
    font-size: 1.5em;
    padding: $spacing * 1.5;

    .perYear {
      line-height: inherit;
      font-size: 1.5rem;
    }
  }
}

.current-subscription {
  max-width: calc(100% - 200px);
}

.custom_plan.disabled {
  opacity: .5;
}
