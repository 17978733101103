.pdf {
  border: 1px solid color(grey-dark);
  padding: 20px;

  h1 {
    text-transform: uppercase;
    text-align: center;
    display: block;
    padding: 5px;
    background: color(main);
    font-size: 2.2rem;
    color: color(blue-dark);
    margin-bottom: $spacing * 2;
  }

  h2 {
    background: color(main);
    font-size: 1.8rem;
    text-transform: uppercase;
    text-align: center;
    display: block;
    padding: 5px 10px;
    margin-bottom: $spacing;
  }

  h3 {
    margin-bottom: $spacing;
    border-bottom: solid 2px color(main-dark);
    display: inline-block;
  }

  p {
    margin-bottom: $spacing;
  }

  ul {
    padding-left: $spacing;
    margin-top: $spacing / 2;
    margin-bottom: $spacing;
  }

  li {
    margin-bottom: $spacing / 2;
  }
}
