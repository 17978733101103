.tab {
  width: 100%;

  &__list {
    display: flex;
    border-bottom: 1px solid color(grey-light);
    padding-bottom: 15px;
    width: 100%;

    &--noborder {
      border: none;
    }

    &--centered {
      justify-content: center;
    }

    &--fullwidth {
      justify-content: center;
      align-items: center;
    }
  }

  &__item {
    position: relative;
    font-size: rem(size(rg));
    font-weight: bold;
    color: color(black);
    border-radius: 50px;
    padding: $spacing / 4 $spacing / 2;
    cursor: pointer;

    &:not(:first-of-type) {
      margin-left: 20px;

      .tab__list--fullwidth & {
        margin-left: 6px;
      }
    }

    &:not(:last-of-type) {
      margin-right: 20px;

      .tab__list--fullwidth & {
        margin-right: 6px;
      }
    }

    .tab__list--fullwidth & {
      flex: 1;
      padding: 2px 10px;
      text-align: center;
    }

    .tab__list--colored & {
      border: 1px solid #f1f1f1;
    }

    .tab__list--colored &--error.react-tabs__tab--selected {
      border-color: rgb(254, 63, 82);
      background: rgb(254, 235, 237);
    }
  }

  &__notify {
    margin-left: 20px;
  }

  &__item {
    &:before {
      content: "";
      position: absolute;
      bottom: -16px;
      left: 0;
      background-color: color(grey-light);
      height: 4px;
      width: 100%;
      transform: scaleX(0);
      transform-origin: 100%;
      transition: transform 0.4s;

      .tab__list--noborder & {
        display: none;
      }
    }

    &--noborder:before {
      display: none;
    }

    &:hover:before {
      transform: scaleX(1);
      transform-origin: 0;
      transition: transform 0.4s;
    }
  }

  &_item--active {
    &:before {
      transform: scaleX(1);
    }
  }

  &__panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}

.react-tabs__tab--selected {
  background-color: #F1F1F1;

  .tab__list--colored & {
    background-color: rgba(33, 188, 144, 0.1);
    border-color: #21bc90;
  }

  &:before {
    transform: scaleX(1);
  }
}