.title {
  &-rg {
    font-size: rem(size(md));
    line-height: 1.8;
    letter-spacing: .1em;
    text-transform: uppercase;
    color: color(blue-dark);
    margin-bottom: $spacing * 1.25;
    font-weight: 800;
  }

  &-xl {
    font-size: rem(size(xl));
    font-weight: 800;
    color: color(blue-dark);
    margin-bottom: $spacing/2;
    margin-left: -14px;

    &:before {
      @extend .bullet;
      font-size: 8px;
      margin-right: 6px;
    }

    &.nobullet {
      margin-left: 0;

      &:before {
        content: none;
      }
    }
  }

  &-xxl {
    font-size: rem(size(xxl));
    font-weight: 700;
  }

  &-xxxl {
    font-size: rem(size(xxxl));
    font-weight: 900;
    color: color(black);
    margin-bottom: $spacing;

    .underline {
      display: inline-block;
      @include underline(100%, 4px, 0, 0);
    }
  }

  &-zilla {
    font-size: 10rem;
    line-height: 1;
    font-weight: 900;
    color: color(light);
  }

  &-separator {
    color: color(grey-dark);
    display: flex;
    align-items: center;
    margin: 10px 0;

    &:after,
    &:before {
      content: "";
      flex: 1;
      border-bottom: solid 1px color(grey);
      vertical-align: middle;
    }

    &:after {
      margin-left: 30px;
    }

    &:before {
      margin-right: 30px;
    }
  }

  &-main {
    @include breakpoint(xs) {
      font-size: 3rem;
      line-height: 4rem;

      .text-formatted {
        line-height: 4rem;
      }
    }
    @include breakpoint(sm) {
      font-size: 4rem;
      line-height: 5rem;

      .text-formatted {
        line-height: 5rem;
      }
    }
    @include breakpoint(md) {
      font-size: 5.8rem;
      line-height: 7rem;

      .text-formatted {
        line-height: 7rem;
      }
    }
  }

  &-secondary {
    font-size: 3rem;
    font-weight: 900;
  }
}
