$bottompercentageheight: 30px;

%category {
  display: inline-block;
  background: color(grey-lighter);
  border-radius: 3px;
  color: color(main-dark);
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 2px 5px;
  font-weight: 700;
}

.card {
  transition: all 0.3s;
  border-radius: 9px;
  box-shadow: 0 0 0 4px transparent inset, 0 10px 40px 0 rgba(0, 0, 0, 0.1);
  background: color(light);
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  border: 1px solid color(grey-light);


  &--applied:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    border-radius: 5px;
    background: color(blue-king);
  }

  &--project {
    .card {
      &_header {
        padding: $spacing/2;
      }
    }
  }

  &--pastille {

    &:after {
      content: "";
      animation: highlight-animation-transparent 5s linear 0s infinite;
      display: block;
      width: 13px;
      height: 13px;
      background-color: color(orange);
      border-radius: 50%;
      position: absolute;
      top: 10px;
      right: 10px;
      transform: translate(50%, -50%);
    }
  }

  &--fullheight {
    height: 100%;
  }

  &-green-stripe:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background: color(main);
  }

  &--percentage {
    padding-bottom: $bottompercentageheight;
  }

  &_content {
    width: 100%;
  }

  &_header {
    width: 100%;
    padding: $spacing $spacing $spacing/2;
    border-bottom: 1px solid color(grey-light);

    &>div {
      min-width: 120px;
    }
  }

  &_button {
    min-width: 0;
    height: 100%;
    font-size: 2.3rem;
    padding: 0;
    color: #111517;
    @extend .transparent;
    @extend .icon;
    @extend .icon-more-horizontal-;
  }

  &_category {
    @extend %category;
  }

  &_label {
    @extend %category;
    color: color(blue-dark);
    font-weight: 800;
    font-size: rem(size(sm));
    text-transform: uppercase;
  }

  &_pricing {
    display: inline-block;
    min-width: 130px;
    text-transform: uppercase;
    background: color(grey-lighter);
    color: color(main-dark);
    padding: 4px 10px;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 800;
    margin-bottom: $spacing/2;
    border-radius: 3px;

    @media (max-width: $sm) {
      text-align: center !important;
      display: block;
      width: 100%;
    }
  }

  &_title {
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: none;
    letter-spacing: normal;
    color: color(blue-dark);
    font-weight: 800;
    padding-bottom: $spacing/2;
    font-size: rem(size(xxl));

    &--applied:before {
      content: "\e856";
      font-size: 1.8rem;
      font-family: "feather";
      color: color(blue-king);
      font-weight: 900;
      margin-right: 10px;
    }
  }

  &_footer {
    width: 100%;
    padding: $spacing/4 $spacing/1.5;
    overflow: hidden;
  }

  &_matching {
    border-radius: 25px;
    background: color(grey-light);
    height: 10px;
    margin-bottom: 8px;
    width: 100%;
  }

  &--clickable {
    cursor: pointer;
  }

  &--clickable:hover {
    box-shadow: 0 10px 40px #e0e0e0;
    transition: all 0.3s;
  }

  &_bottompercentage {
    display: block;
    height: $bottompercentageheight;
    position: absolute;
    bottom: 0;
    left: 0;
    background: color(main);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    &text {
      height: $bottompercentageheight;
      position: absolute;
      bottom: 0;
      left: 0;
      line-height: $bottompercentageheight;
    }
  }

  &_perc-value {
    position: absolute;
    transform: translateX(-100%);
  }

  &_vrule {
    width: 2px;
    height: 30px;
    border-left: solid 1px color(grey);
  }

  &__badge {
    padding: 2px 15px;
    vertical-align: middle;
    margin: 4px;
    background: transparentize(color(blue-king), 0.9);
    display: inline-block;
    border-radius: 20px;
    font-weight: 800;
    font-size: 1.3rem;
    color: color(blue-king);

    &--warning {
      background: transparentize(color(orange), 0.9);
      color: color(orange);
    }

    &--error {
      background: transparentize(color(error), 0.9);
      color: color(error);
    }

    &--main {
      background: transparentize(color(main), 0.9);
      color: color(main);
    }

    &--grey {
      background: transparentize(black, 0.9);
      color: #111517;
    }
  }

  &_avatar {
    width: 75px;
    height: 75px;
  }

  &_noPlan {
    filter: blur(3px);
    transition: filter 0.4s ease;
    color: color(dark);
    font-size: 1.4rem;
    font-weight: 700;
    flex-direction: column;
    text-align: center;
    line-height: 2rem;

    &:hover {
      filter: blur(0.1px);
    }
  }

  &_section {
    position: relative;
    z-index: 0;

    &:not(:last-of-type) {
      padding-bottom: $spacing;
      border-bottom: 2px solid #f2f3f4;
    }
  }
}

.card--sm {
  flex-flow: column nowrap;
}

@media (min-width: 768px) {
  .card--md {
    flex-flow: row;
  }
}

@keyframes highlight-animation-transparent {
  0% {
    box-shadow: 0 0 0 0 transparentize(color(orange), $amount: 0.6), 0 0 0 3px transparent;
  }

  100%,
  40% {
    box-shadow: 0 0 0 16px transparentize(color(orange), $amount: 1), 0 0 0 3px transparent;
  }
}