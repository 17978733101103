%hideBox {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 1px;
}

.check {
  font-size: rem(size(md));
  display: inline-block;
  position: relative;
  cursor: pointer;

  &_box {
    @extend %hideBox;

    &:disabled, &.disabled {
      opacity: .5;
  
      & + .check_label {
        opacity: .5;
      }
    }
  }

  &_label {
    &:before {
      @extend .icon;
      @extend .icon-square;
      display: inline-block;
      margin-right: .25em;
      color: color(grey);
      vertical-align: middle;
      transition: $transition;
    }

    .check_box:checked + &:before {
      @extend .icon-check-square;
      color: color(main-dark);
      font-weight: 900;
      position: relative;
      right: -.04375em;
    }
  }
}
