.tooltip {
  position: relative;

  &:after {
    z-index: 9999;
    font-family: $font-text;
    transition: transform 0.6s ease, opacity 0.4s ease;
    pointer-events: none;
    content: attr(data-text);
    position: absolute;
    left: 50%;
    top: 120%;
    display: block;
    padding: 5px 10px;
    font-size: 1.2rem;
    font-weight: 600;
    background: color(light);
    box-shadow: $shadow;
    border-radius: 3px;
    color: #111517;
    opacity: 0;
    width: 100vw;
    max-width: 240px;
    transform: translateY(5px) translateX(-50%);
  }

  &__pre {
    &:after {
      white-space: pre;
    }
  }

  &:hover:after {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }

  &--left:after {
    transform: translateY(5px) translateX(-100%);
  }

  &--left:hover:after {
    transform: translateY(0) translateX(-100%);
  }

  &--right:after {
    transform: translateY(5px) translateX(0%);
  }

  &--right:hover::after {
    transform: translateY(0) translateX(0%);
  }
}
