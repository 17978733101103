.tag{
    display: inline-block;
    border:2px solid color(main);
    margin-right:10px;
    margin-top:10px;
    &__label{
      background-color:white;
      display:inline-block;
      padding:5px 10px;
      font-size:14px;
      color:color(blue-dark);
    }
    &__level{
      color:color(blue-dark);
      background-color:color(main);
      display:inline-block;
      padding:5px 10px;
      font-weight: 900;
    }
}
