// Wrapper

.chat {
  display: flex;
  width: 100%;
  min-height: calc(100vh - #{$header-height} - 1px);
  margin-bottom: -$abs-footer-height;
  background: color(white);
  
  @include breakpoint(lg) {
    min-height: calc(100vh - #{$header-height});
  }
  @include breakpoint-max(xl) {
    margin-bottom: -$abs-footer-height-md;
  }
  @include breakpoint-max(md) {
    margin-bottom: -$abs-footer-height;
  }

  .banner~section & {
    padding-top: $banner-height;
    max-height: calc(100vh - #{$header-height} - #{$banner-height});

    @include breakpoint(lg) {
      min-height: calc(100vh - #{$header-height});
    }
  }

  &__nomsgs {
    max-height: 100%;
    flex: 1;
    display: flex;
    position: relative;
    align-items: center;

    &-text {
      position: absolute;
      top: 20px;
      left: 20px;
    }
  }
}