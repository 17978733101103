.page-transition {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  pointer-events: none;

  &:after,
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    transform: translateY(-100%);
  }

  &:before {
    background: color(light);
  }

  &:after {
    background: color(main);
  }
}
