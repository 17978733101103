.empty {
  &_title {
    font-size: 1.4rem;
    font-weight: 800;
  }

  &_cta {
    display: inline-block;
    position: relative;
    padding: $spacing * 4 0 $spacing;
  }

  &_arrow {
    position: absolute;
    width: 70px;
    left: 0;
    transform: translatex(calc(-100% - 10px));
    top: calc(50% - 20px);
  }

  &_illustration {
    display: block;
    max-width: 800px;
    margin: 100px auto;
  }
}
