.box-radio {
  display: block;
  height: 100%;
  position: relative;
  cursor: pointer;

  &_box {
    @extend %hideBox;

    &:disabled + .box-radio_label,
    &:disabled + .radio_label {
      opacity: .5;
      cursor: default;
    }

    &:not([disabled]) + &_label:hover {
      color: color(light);
      background-color: color(main);
      border-color: color(main);
    }
  }

  &_label {
    border: 2px solid color(grey-light);
    text-align: center;
    font-weight: 700;
    padding: $spacing/3;
    display: block;
    transition: $transition;
    background: color(light);
    height: 100%;

    .box-radio_box:checked + & {
      color: color(black);
      background-color: color(main);
      border-color: color(main);
    }
    @include breakpoint(lg) {
      padding: $spacing;
    }
  }
}
