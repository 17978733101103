.chatDialog {
  background: color(white);
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100vh - #{$header-height} - 1px);

  @include breakpoint(lg) {
    height: calc(100vh - #{$header-height} - 1px);
  }

  position: relative;

  &__body {
    flex: 1;
    overflow: auto;
  }
}