.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &-nowrap {
    flex-wrap: nowrap;
  }
}

.row-guttered {
  margin-left: -#{$spacing};
  margin-right: -#{$spacing};
  width: calc(100% + #{$spacing * 2});
}

.row-guttered-half {
  margin-left: -#{$spacing/2};
  margin-right: -#{$spacing/2};
  width: calc(100% + #{$spacing});
}

.row-guttered-quarter {
  margin-left: -#{$spacing/4};
  margin-right: -#{$spacing/4};
  width: calc(100% + #{$spacing/2});
}

.hide {
  display: none;
}

.flex {
  display: flex;

  &-none {
    flex: none;
  }

  &-1 {
    flex: 1;
  }
}

.vertical {
  flex-direction: column;
}

.start {
  justify-content: flex-start;
  text-align: start;
}

.center {
  justify-content: center;
  text-align: center;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: flex-end;
}

.end {
  justify-content: flex-end;
  text-align: end;
}

.top {
  align-items: flex-start;
  align-content: flex-start;
}

.middle {
  align-items: center;
  align-content: center;
}

.bottom {
  align-items: flex-end;
  align-content: flex-end;
}

.around {
  justify-content: space-around;
  align-content: space-around;
}

.between {
  justify-content: space-between;
}

.baseline {
  align-items: baseline;
}

.stretch {
  align-items: stretch;
}

.grow {
  flex-grow: 1;
}

$nbCols: 12;
@for $i from 1 through $nbCols {
  .col-#{$i} {
    flex-grow: 1;
    width: calc(#{100% * $i / $nbCols});
    max-width: calc(#{100% * $i / $nbCols});
  }

  .gutter-#{$i} {
    max-width: calc(#{100% * $i / $nbCols} - 10px) !important;
  }
}
@each $breakpoint in $breakpoints {
  $name: nth($breakpoint, 1);
  $size: nth($breakpoint, 2);
  @media only screen and (max-width: #{$size - 1}) {
    .hide-under-#{$name} {
      display: none !important;
    }
  }
  @media only screen and (min-width: $size) {
    @for $i from 1 through $nbCols {
      .col-#{$i}-#{$name} {
        flex-grow: 1;
        width: calc(#{100% * $i / $nbCols});
        max-width: calc(#{100% * $i / $nbCols});
      }

      .gutter-#{$i}-#{$name} {
        max-width: calc(#{100% * $i / $nbCols} - 20px) !important;
      }

      .col-#{$name} {
        box-flex: 1;
        flex: 1 auto 0;
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
      }
    }

    .hide-#{$name} {
      display: none;
    }

    .col-none-#{$name} {
      flex: none;
      width: auto;
      max-width: 100%;
    }

    .start-#{$name} {
      justify-content: flex-start;
      text-align: start;
    }

    .center-#{$name} {
      justify-content: center;
      text-align: center;
    }

    .end-#{$name} {
      justify-content: flex-end;
      text-align: end;
    }

    .top-#{$name} {
      align-items: flex-start;
      align-content: flex-start;
    }

    .middle-#{$name} {
      align-items: center;
      align-content: center;
    }

    .bottom-#{$name} {
      align-items: flex-end;
      align-content: flex-end;
    }

    .around-#{$name} {
      justify-content: space-around;
      align-content: space-around;
    }

    .between-#{$name} {
      justify-content: space-between;
      align-content: space-between;
    }
  }
}
