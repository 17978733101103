.text-editor-root,
.text-formatted {
  line-height: 1.8em;

  h1,
  h2,
  h3,
  h4 {
    margin: $spacing / 2 0;
  }

  ul {
    list-style: inherit;
    padding-left: 2em;
  }

  blockquote {
    border-left: 0;
    color: #666;
    font-family: inherit;
    font-style: italic;
    font-size: 1.2em;
    margin: $spacing 0;
    padding: 10px 0;
  }

  .input_area {
    border: 1px solid color(grey-light);
    border-radius: 8px;
    overflow: hidden;
  }

  .input_controls {
    background: #F1F1F1;
    padding: .2em 1em 0;
  }

  .text-editor {
    border-top: 1px solid color(grey-light);
    cursor: text;
    font-size: 16px;
    font-weight: normal;
    padding: 1em;
    max-height: 300px;
    overflow-y: scroll;

    .DraftEditor-root {
      min-height: 100px;
    }

    .public-DraftEditor-content,
    .public-DraftEditorPlaceholder-root {
      margin: 0 -15px -15px;
      padding: 15px;
    }

    .public-DraftEditorPlaceholder-root {
      position: absolute;
      width: 100%;
      font-size: 0.9em;
      font-weight: 200;
      color: #111517;
    }

    .public-DraftEditorPlaceholder-inner {
      position: absolute;
    }

    .public-DraftStyleDefault-pre {
      background-color: rgba(0, 0, 0, 0.05);
      font-family: "Inconsolata", "Menlo", "Consolas", monospace;
      font-size: 16px;
      padding: 20px;
    }
  }

  .text-editor-hidePlaceholder {
    .public-DraftEditorPlaceholder-root {
      display: none;
    }
  }

  .text-editor-focused {
    max-height: inherit;
    overflow-y: inherit;
  }

  .text-editor-controls {
    display: inline-block;
    font-size: 0.875em;
    color: #111517;
    margin-bottom: 5px;
    margin-right: 10px;
    user-select: none;
  }

  .text-editor-style-btn {
    height: 27px;

    border: 1px solid color(grey-light);
    cursor: pointer;
    margin-right: 6px;
    padding: 0 6px;
    vertical-align: top;
    display: inline-block;

    & > .icon {
      font-weight: bold;
    }

    & > .custom-icon:before {
      width: 10.5px;
      height: 10.5px;
      margin-top: 5px;
      background-color: #111517;
    }
  }

  .text-editor-active-btn {
    color: color(black);
  }

  .text-editor-active-btn {
    background-color: color(grey-light);

    & > .custom-icon:before {
      background-color: color(black);
    }
  }
}
