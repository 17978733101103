$switch-width: 40px;

.switch {
  position: relative;
  display: inline-block;
  width: $switch-width;
  height: 10px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: lighten(color(main), 20);

      &:before {
        transform: translateX(30px);
        background-color: color(main);
      }
    }
  }

  &--onOff {
    vertical-align: middle;
    margin-right: ($switch-width / 2) + 10px;
    position: relative;

    .label:after {
      position: absolute;
      left: calc(100% + 10px);
      top: -4px;
      content: attr(data-label-off);
    }

    input:checked ~ .label:after {
      content: attr(data-label-on);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: lighten(color(grey-dark), 20);
    border-radius: 50px;

    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: -5px;
      bottom: -5px;
      transition: .4s;
      background-color: color(grey-dark);
      border-radius: 50px;
    }
  }
}
