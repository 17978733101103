%bg-props {
  background-size: cover;
  background-repeat: no-repeat;
}

.shadow {
  box-shadow: $shadow;
}

.bg {
  @each $name, $hex in $colors {
    &-#{$name} {
      background-color: $hex;
    }
  }

  &-login,
  &-register {
    background-image: url("#{$cdn-url}login.jpg");
    background-position: left top;
    z-index: 1;
    position: relative;
    @extend %bg-props;

    &:after {
      content: "";
      display: block;
      width: 20%;
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      background: color(black);
      z-index: -1;
    }
  }

  &-register {
    background-image: url("#{$cdn-url}register.jpg");
    background-position: center center;

    &:after {
      left: auto;
      right: 41.67%;
      right: calc(100% / 12 * 6);
    }
  }

  &-animate {
    padding-left: 0;
    padding-right: 0;
    animation: 1s .5s strech both;
  }
}
