.animated-arrow {
  &__bg {
    fill: color(main);
  }

  &__outline {
    animation: float 2s ease infinite;

  }
}
@keyframes float {
  0% {
    transform: translate(0);
  }

  50% {
    transform: translate(-20px,-4px);
  }

  100% {
    transform: translate(0);
  }
}
