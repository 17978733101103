.label {
  display: block;
  font-size: 0.875em;
  margin-bottom: 1em;
  font-weight: 800;
  transition: $transition;
  @include capitalize();

  &--error {
    color: color(error);
  }
}

.error {
  display: block;
  font-size: 0.85em;
  font-weight: 700;
  padding-top: 0.25em;
  color: color(error);
  animation: show 0.5s;
}

.t {
  @each $name, $hex in $colors {
    &-#{$name} {
      color: $hex;

      &:link,
      &:visited {
        color: $hex;
      }

      &.btn:active,
      &.btn:hover,
      &.link:active,
      &.link:hover {
        color: darken($hex, 10%);
      }
    }
  }
  @each $name, $size in $sizes {
    &-#{$name} {
      font-size: rem($size);
      line-height: rem($size);
    }

    &-#{$name}-px {
      font-size: px($size);
    }
    @each $breakpointname, $breakpoint in $breakpoints {
      @include breakpoint($breakpointname) {
        &-#{$name}-#{$breakpointname} {
          font-size: rem($size);
        }
      }
    }
  }

  &-left,
  &-left div {
    text-align: left;
  }

  &-right,
  &-right div {
    text-align: right;

    &-max-md {
      @include breakpoint-max(md) {
        text-align: right;
      }
    }
  }

  &-center,
  &-center div {
    text-align: center;
  }

  &-justify,
  &-justify div {
    text-align: justify;
  }

  &-regular {
    font-weight: 400;
  }

  &-semibold {
    font-weight: 600;
  }

  &-bold {
    font-weight: 700;
  }

  &-xbold {
    font-weight: 900;
  }

  &-italic {
    font-style: italic;
  }

  &-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-capitalize {
    @include capitalize();
  }

  &-spaced {
    letter-spacing: $letter-spacing;
  }

  &-upper {
    text-transform: uppercase;
  }

  &-lower {
    text-transform: lowercase;
  }

  &-none {
    text-transform: none;
    letter-spacing: normal;
  }

  &-thin {
    font-weight: 200;
  }

  &-middle {
    font-weight: 400;
  }

  &-pointer {
    cursor: pointer;
  }
  &-v-align {
    &-bottom {
      vertical-align: text-bottom;
    }
    &-sub {
      vertical-align: sub;
    }
    &-mid {
      vertical-align: middle;
    }
  }
  &-pre {
    white-space: pre;
  }
}
