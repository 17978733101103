.react-cookie-banner {
  font-weight: 700;
  position: fixed;
  bottom: $spacing;
  left: $spacing * 2;
  margin: auto;
  z-index: 110;
  width: 420px;
  max-width: 90%;
  border-radius: $border-radius;
  align-items: center;
  background: color(light);
  box-shadow: $shadow;
  padding-bottom: 20px;

  &:before {
    content: "";
    position: absolute;
    top: -30px;
    left: 20%;
    z-index: 21;
    height: 175px;
    width: 210px;
    background-image: url("#{$cdn-url}v3/cookies.png");
    background-repeat: no-repeat;
    background-size: 100%;

    @media (max-width: 520px) {
      background-image: none;
    }
  }

  @media (max-width: 520px) {
    bottom: $spacing;
    left: $spacing;
  }

  .cookie-message {
    padding: 150px $spacing $spacing;
    color: color(blue-dark);
    display: block;
    @media (max-width: 520px) {
      padding: 20px;
    }

    a {
      margin-left: 5px;
      font-weight: 900;
      color: color(main-dark);

      &:hover {
        text-decoration: none;
      }
    }
  }

  .button-close {
    background: color(main);
    display: block;
    color: color(blue-dark);
    font-weight: 700;
    border: 0;
    border-radius: 50px;
    padding: ($spacing / 2) ($spacing * 3);
    margin-left: 20px;
    cursor: pointer;

    @media (max-width: 520px) {
      margin: auto;
    }
  }
}
