.ReactModal {
  &__Overlay {
    background-color: transparentize(color(light), .5) ;
    z-index: 1000;
    animation: show $time $easing;
  }

  &__Content {
    left: 50% ;
    top: 50% ;
    right: auto ;
    bottom: auto !important;
    border-radius: 9px ;
    box-shadow: 0 0 0 4px transparent inset, 0 10px 40px 0 rgba(0, 0, 0, 0.1) ;
    border: 1px solid color(grey-light) ;
    padding: $spacing/2;
    max-height: 90%;
    max-width: 960px;
    max-height: calc(100% - #{$spacing * 2});
    max-width: calc(100% - #{$spacing * 2});
    margin: auto;
    @extend .shadow;
    @include breakpoint(md) {
      min-width: 80%;
      max-width: 95%;
    }
    @include breakpoint(lg) {
      padding: $spacing * 2;
      min-width: inherit;
      max-width: 920px;
    }

    .close {
      font-size: px(size(xl));
      position: absolute;
      top: 0;
      right: 0;
      padding: $spacing/2;
      border: none;
      cursor: pointer;
      outline: 0;
      @extend .icon;
      @extend .icon-x;
      background: color(white);

      &:hover {
        color: color(main);
      }
    }

    .html-content {
      max-height: 50vh;
      overflow-y: scroll;
      margin-bottom: 20px;
    }
  }
}