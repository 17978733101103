.header {
  $border: 1px solid color(grey-lighter);

  %border {
    border-bottom: $border;
  }

  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height;
  transition: height $time $easing;

  .header_content {
    background: color(white);
    border-bottom: 1px solid color(grey-light);
  }

  &.banner + #main {
    padding-top: $banner-height;
  }

  .header_logo .logo {
    overflow: hidden;

    &_underline {
      transition: transform 0.8s ease;
    }

    &_letter {
      transition: width 0.8s ease;
      //Back animation
      @for $i from 1 through 8 {
        &:nth-of-type(#{9 - $i}) {
          transition: transform 0.8s #{$i * 0.05}s ease;
        }
      }
    }
  }

  &-empty {
    .header_content {
      background: none;
    }

    .header_auth,
    .header_mobile {
      display: none;
    }
    @include breakpoint(lg) {
      background: none;

      .header_wrapper {
        max-width: 84.5%;
      }

      .header_logo {
        color: color(light);

        &:hover {
          color: color(light);
        }
      }
    }
  }

  &_logo {
    color: #111517;
    padding-top: 2px;
  }

  &_link {
    height: $header-height/2;
    line-height: $header-height/2;
    font-size: 2rem;
  }

  &_profile {
    width: 250px;
    @include breakpoint-max(lg) {
      width: auto;
    }

    .dropdown_content {
      max-height: inherit;
      z-index: 101;

      .dropdown_item {
        padding: $spacing/2 $spacing;

        .icon {
          margin-right: $spacing/2;
        }
      }
    }
  }

  &_stats {
    overflow: hidden;
    flex: 1;
  }

  &_burger {
    &.open {
      color: color(main-dark);

      & + .header_menucontainer {
        visibility: visible;
        opacity: 1;

        .header_menu {
          transform: none;
        }
      }
    }
  }

  &_menucontainer {
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    border-left: $border;
    right: 0;
    top: 56px;
    min-height: calc(100vh - 56px);
    max-height: calc(100vh - #{$header-height});
    padding-bottom: $spacing;
    visibility: hidden;
    transition: $transition;
    width: 100%;
    overflow: scroll;
    opacity: 0;
  }

  &_menu {
    transition: $transition;
    position: absolute;
    top: 0;
    right: 0;
    height: auto;
    background: color(light);
    max-width: 100%;
    min-width: 250px;
    min-height: 100%;
    transform: translateX(100%);
    @media (max-width: $sm) {
      min-width: 85%;
    }
  }

  &_menuwrapper {
    padding-bottom: 125px;
  }

  &_menuchevron {
    transition: all 0.5s ease;
    content: "\e84d";
    font-family: "feather";
    display: inline-block;
    position: fixed;
    right: 245px;
    top: 50%;
    font-size: 3rem;
    font-weight: 900;
    padding: 10px;
    color: color(grey-light);
    opacity: 0;
    z-index: 1;
    transform: translateX(50px);
    @media (max-width: $sm) {
      right: 85%;
    }

    &--open {
      transform: none;
      opacity: 1;
    }
  }

  &_item {
    background: transparent;
    border: none;
    display: inline-block;
    width: 100%;
    @extend .header_link;
    @extend .t-blue-dark;
    @extend %border;
    font-size: rem(size(lg));
    padding: 0 $spacing;

    i {
      @extend .icon;
      vertical-align: middle;
      margin-right: $spacing/2;
    }
  }

  &__notif {
    position: relative;
    margin: 0 10px;
    padding: 2px;
    background: #ffffff;
    border-radius: 8px;
    line-height: 2rem;
    display: inline-block;
    text-align: center;
    .btn {
      padding: 0;
      font-size: 1.8rem;
      line-height: 1.8rem;
    }

    &.highlight {
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.17);
      background: color(light);

      &:after {
        @extend .bullet;
        background:#ed6001;
        animation: highlight-animation 4s linear 1s infinite;
        position: absolute;
        top: -2px;
        right: -2px;
        border-radius: 50%;
        border: none;
        width: 10px;
        height: 10px;
        font-size: px(size(sm));
      }
    }

    &.unactive .icon {
      color: color(grey-dark);
    }
  }

  &_wrapper {
    min-height: $header-height;
    height: 100%;

    &.banner + #main {
      min-height: $banner-height;
    }
  }
}
@keyframes highlight-animation {
  0% {
    box-shadow: 0 0 0 0 transparentize(#ed6001, $amount: 0.4);
  }

  100%,
  40% {
    box-shadow: 0 0 0 15px transparentize(#ed6001, $amount: 1);
  }
}


