.bullet {
  content: "";
  border-radius: 50px;
  display: inline-block;
  background: color(orange);
  height: 8px;
  width: 8px;
}

.progress {
  @extend .bullet;
  font-size: px(size(sm));
  margin: $spacing/2;

  &.validated {
    border: 2px solid color(blue-dark);
  }

  &.empty {
    background: color(blue-dark);
  }
}
