$space-pic-msg: 10px;
$margin-msgs: 5px;
$padding-top-day: 55px;
$margin-top-day: 55px;
$whitespace-date: 0 20px;
$text-apparition-delay: 0.5s;
$picture-apparition-delay: 0.8s;

.chatHistory {
  min-height: 100%;
  display: flex;
  vertical-align: bottom;
  justify-content: flex-end;
  flex-direction: column;
  padding: 20px 5px 10px;
  @include breakpoint(md) {
    padding: 20px 20px 10px;
  }

  &__day-msgs {
    position: relative;
    padding-top: $padding-top-day;
    margin-top: $margin-top-day;
  }

  &__day {
    color: color(grey);
    background: color(white);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 0;
    left: 50%;
    padding: $whitespace-date;
  }

  &__user-msgs {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    &.ownMessages {
      flex-direction: row-reverse;
      max-width: 600px;
      margin-left: auto;

      .chatHistory__user-msgs-time {
        right: 0;
        left: auto;
      }
    }
  }

  &__user-msgs-time {
    position: absolute;
    top: 100%;
    left: 0;
    margin: auto;
    color: color(grey-dark);
    font-weight: 400;
    font-size: 0.9em;
    text-align: center;
  }

  &__user-msgs-infos {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
  }

  &__user-msgs-picture {
    animation: scaleIn $picture-apparition-delay ease;
  }

  &__user-msgs-container {
    .ownMessages & {
      align-self: flex-end;
    }
  }

  &__msg-text-wrapper {
    margin-bottom: $margin-msgs;

    &--side {
      display: inline-block;

      &:not(:first-of-type) {
        margin: 0 5px 5px;
      }
    }

    .text {
      animation: slideIn-left $text-apparition-delay ease;
      padding: 6px 16px;
      border-radius: 5px 20px 20px 5px;
      display: inline-block;
      white-space: pre-wrap;
      word-break: break-word;
      background: #F1F1F1;

      &--file {
        @extend %text-files-right;
      }

      &--media {
        @extend %text-media;
      }
    }

    &:first-of-type .text {
      border-top-left-radius: 20px;
    }

    &:last-of-type .text {
      border-bottom-left-radius: 20px;
    }

    .ownMessages & {
      .text {
        animation: slideIn-right $text-apparition-delay ease;
        color: color(light);
        background: color(main-dark);
        border-radius: 20px 5px 5px 20px;

        &--file {
          @extend %text-files;
        }

        &--media {
          @extend %text-media;
        }
      }

      &:first-of-type .text {
        border-top-right-radius: 20px;
      }

      &:last-of-type .text {
        border-bottom-right-radius: 20px;
      }
    }
  }

  &__archived-msg {
    text-align: center;
    margin: $margin-top-day 0;
  }
}
@keyframes slideIn-left {
  0% {
    transform: translateX(-10px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideIn-right {
  0% {
    transform: translateX(10px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes scaleIn {
  0% {
    transform: scale(0.75);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

%text-files {
  border: 1px solid color(grey-lighter);
  position: relative;
  background: color(light);
  font-weight: 700;
  cursor: pointer;

  a {
    color: color(dark);
  }

  &:before {
    content: "\E82A";
    font-size: 1.3em;
    font-family: "feather";
    font-weight: 300;
    padding: 0 10px;
    top: 50%;
    position: absolute;
    color: color(blue-grey);
    left: 0;
    transform: translateX(-100%) translateY(-50%);
  }
}

%text-files-right {
  @extend %text-files;

  &:before {
    left: auto;
    right: 0;
    transform: translateX(100%) translateY(-50%);
  }
}

%text-media {
  background: color(light);
  padding: 0;
  display: inline-block;
  overflow: hidden;
  border-radius: 10px;
  width: 200px;
  height: 200px;
  box-shadow: 0 0 15px 0 color(grey-light);
  border: 1px solid color(grey-light);

  a,
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
