.footer {
  align-items: center;
  background: color(black);
  color: white;
  flex-shrink: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: #{$spacing * 2} 0 0;
  @include breakpoint(md) {
    flex-direction: row;
    padding: #{$spacing * 4} 5% #{$spacing * 2};
  }

  .row {
    @include breakpoint-max(md) {
      flex-direction: column-reverse;
      text-align: center;
    }
  }

  a {
    color: white;
  }

  &__logowrapper {
    flex: 1;
    margin-bottom: $spacing;
    padding: 0;
    @include breakpoint(md) {
      margin-bottom: $spacing * 4;
      padding-left: $spacing * 4;
      padding-right: $spacing;
    }
  }

  &__sitemap {
    width: 100%;
    display: flex;
    flex: 1;
    padding: $spacing #{$spacing / 2} #{$spacing * 2};
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    @include breakpoint(sm) {
      flex-direction: row;
      text-align: left;
      justify-content: space-around;
      padding: $spacing #{$spacing * 2} #{$spacing * 2} $spacing;
    }
    @include breakpoint(md) {
      padding: 0;
      flex: 4;
    }
  }

  &__social {
    @include breakpoint-max(md) {
      text-align: center;
      margin-top: 0;
      padding: $spacing;
    }

    p {
      @include breakpoint-max(md) {
        display: none;
      }
    }
  }
}

.abs-footer {
  position: relative;
  z-index: 19;
  height: $abs-footer-height-xl;
  background: color(black);
  color: color(white);
  padding: 10px 0;
  @include breakpoint-max(sm) {
    padding: 0;
    text-align: center;
  }
  @include breakpoint-max(xl) {
    height: $abs-footer-height-md;
  }
  @include breakpoint-max(md) {
    height: $abs-footer-height;
  }

  & > div {
    height: 100%;
    @include breakpoint-max(md) {
      p {
        display: none;
      }
      padding-left: $spacing;
      padding-right: $spacing;
    }

    & > * {
      margin: $spacing/2;
    }

    & > :first-child {
      margin-left: $spacing;

      @include breakpoint-max(md) {
        margin-left: $spacing/2;
      }
    }

    & > :last-child {
      margin-right: $spacing;

      @include breakpoint-max(md) {
        margin-right: $spacing/2;
      }
    }
  }
}
