$clear-button-width: 100px;

.chatFilesPreview {
  display: block;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  left: 0;
  right: 0;
  overflow: hidden;

  &__wrapper {
    padding: 40px 20px 20px;
    background: color(grey-light);
    animation: slideUp 0.8s ease;
    overflow-x: scroll;
    white-space: nowrap;
    padding-right: $clear-button-width + 10px;
  }

  &__title {
    position: absolute;
    top: 12px;
    left: $spacing;
    vertical-align: middle;
    font-weight: 700;
    margin-bottom: $spacing/2;
    color: transparentize(color(blue-dark), 0.5);
  }

  &__item {
    vertical-align: middle;
    display: inline-block;
    font-size: 0.9em;
    color: transparentize(color(blue-dark), 0.2);
    border-radius: 5px;
    padding: 5px 10px;
    width: 150px;
    height: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    &--fileicon {
      background-size: 40% auto;
      background-position: center 80%;
    }

    .filename,
    .filetype {
      position: absolute;
      left: $spacing/2;
      right: $spacing/2;
      font-weight: 700;
    }

    .filename {
      color: color(light);
      top: $spacing/4;
    }

    .filetype {
      top: $spacing;
      color: transparentize($color: color(light), $amount: 0.2);
      font-size: 0.8em;
    }

    * {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__filter {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(49, 69, 96, 0.7) 18%, rgba(49, 69, 96, 0) 60%, rgba(49, 69, 96, 0));
  }

  &__clear {
    right: 0;
    top: 0;
    background: color(grey-light);
    color: color(blue-grey);
    font-weight: 600;
    border: none;
    height: 100%;
    cursor: pointer;
    position: absolute;
    width: $clear-button-width;
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}
