$list-width: 220px;
$list-width-large: 320px;
$list-width-small: 90px;

.chatcontactlist {
  max-height: calc(100vh - #{$header-height} - 1px);
  overflow: auto;
  position: relative;
  background: color(white);
  border-right: solid 1px color(grey-lighter);

  @include breakpoint(md) {
    min-width: $list-width;
  }

  @include breakpoint(lg) {
    width: $list-width-large;
  }

  &__top {
    width: 100%;
    z-index: 1;
    left: 0;
    top: #{$header-height + 1px};

    @include breakpoint(lg) {
      top: #{$header-height + 1px};
    }

    .banner & {
      top: calc(#{$header-height} + 1px + #{$banner-height});

      @include breakpoint(lg) {
        top: calc(#{$header-height} + 1px + #{$banner-height});
      }
    }
  }

  &__tabs {
    display: flex;
  }

  &__tab {
    border-radius: 0;
    background: none;
    border: none;
    outline: none;
    flex: 1;
    padding: 20px 0;
    color: color(grey-dark);
    font-weight: 600;
    cursor: pointer;

    &.active {
      color: color(blue-dark);
      font-weight: 700;
    }
  }

  &__search {
    width: 90%;
    margin: auto;
    position: relative;

    .icon {
      position: absolute;
      cursor: pointer;
      z-index: 1;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}