.border-grey-light {
  border: 2px solid #f2f3f4;
}

.bb-grey-light {
  border-bottom: 2px solid #f2f3f4;
}

.bb-grey-light-thin {
  border-bottom: 1px solid color(grey-lighter);
}

.ignore-it {
  display: none;
}

.bt-grey-light {
  border-top: 1px solid #f2f3f4;
}

.b-none {
  border: none !important;
}
