.btn {
  font-weight: 700;
  height: $button-rg-height;
  min-width: 110px;
  display: inline-block;
  padding-left: $spacing;
  padding-right: $spacing;
  background: color(main);
  white-space: nowrap;
  color: color(black);
  border: none;
  vertical-align: middle;
  text-align: center;
  line-height: $button-rg-height;
  font-size: rem(size(rg));
  transition: $transition;
  outline: none;
  border-radius: 11px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  @include capitalize();

  &-secondary {
    color: color(light);
    background: color(dark);
  }

  &-warning {
    color: color(orange);
    background: color(light);
    border: $button-rg-border-size solid color(orange);
  }

  &-light {
    background: color(grey-light);
  }

  &-white {
    background: color(light);
    color: color(black);
  }

  &-link {
    display: inline-block;

    &-md {
      height: 50px;
      line-height: 50px;
    }
  }

  &-block {
    width: 100%;
  }

  &-sm {
    height: 30px;
    line-height: 30px;
    min-width: 80px;
  }

  &-xs {
    height: 20px;
    line-height: 20px;
    min-width: inherit;
    padding: inherit;
  }

  &-md {
    height: 40px;
    min-width: 130px;
  }

  &-lg {
    height: 50px;
    line-height: 50px;
    min-width: 150px;
  }

  &-xl {
    height: 75px;
    line-height: 75px;
    min-width: 200px;
    font-size: rem(size(lg));
  }

  &-with-icon .icon {
    padding-left: 10px;
  }

  &:focus,
  &:hover {
    background: darken(color(main), 10%);
  }

  &-light:focus,
  &-light:hover {
    color: color(light);
    background: color(grey-dark);
  }

  &-secondary:focus,
  &-secondary:hover {
    color: darken(color(light), 10%);
    background: lighten(color(dark), 10%);
  }

  &-warning:focus,
  &-warning:hover {
    color: color(light);
    background: color(orange);
  }

  &-white:hover {
    color: color(dark);
    background: color(grey-light);
  }

  &:disabled,
  &-disabled {
    opacity: 0.3;
    cursor: auto;
    pointer-events: none;
  }

  @each $name, $hex in $colors {
    &.#{$name} {
      background: $hex;

      &:hover {
        background: darken($hex, 5%);
      }
    }
  }

  &.trimmed {
    min-width: 0;
  }

  &.bordered {
    border: 2px solid color(grey-lighter);
  }

  &.invalid {
    animation: deny 0.7s linear;
  }

  &.rounded {
    border-radius: 3px;
  }

  &.unclickable {
    pointer-events: none;
  }

  .arrow {
    margin-left: $spacing/3;

    &.left {
      margin-left: 0;
      margin-right: $spacing/3;
    }
  }

  &--rounded {
    border-radius: 25px;
  }

  &--toTop {
    display: none;
    padding: 0;
    min-width: 0;
    height: auto;
    @media (max-width: $md) {
      display: inline-block;
      position: fixed;
      bottom: $spacing * 2;
      right: $spacing * 2;
      background: color(main-dark);
      color: color(light);
      border-radius: 5px;
    }
    &:before {
      content: "\e845";
      font-family: "feather";
      color: color(dark);
      font-weight: 900;
      font-size: 3rem;
      display: inline-block;
      margin: $spacing/2;
    }
  }
}

.btn-icons {
  text-align: right;
  color: color(blue-dark);

  span {
    border: 2px solid color(grey);
    border-radius: 3px;
    margin-left: 5px;
    cursor: pointer;
    font-weight: 700;
    padding: 2px;
    position: relative;

    &.icon-x {
      color: color(error);
    }
  }
}

.disabled {
  cursor: default !important;
}
