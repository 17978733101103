.title-anim {
  position: relative;
  font-weight: 900;
  padding: 100px 0;
  width: 100%;
  max-width: 100%;
  min-height: 300px;
  overflow: hidden;

  &__animationwrapper {
    position: absolute;
    top: 0;
    padding: 30px;
    overflow: hidden;
  }

  &__animated {
    white-space: nowrap;
    color: color(grey-light);
    font-size: 220px;
    line-height: 220px;
    display: inline;
  }

  &__title {
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    pointer-events: none;
    bottom: 0;
    color: color(black);
    display: flex;
    align-items: center;
    @include breakpoint(md) {
      left: 100px;
      right: 100px;
    }
  }
}
