$cdn-url: "https://assets.beelance.io/";

$font-text: "Muli",
sans-serif;
$font-icon: "feather";
$base-line-height: 1.4286em;

$spacing: 20px;

$header-height: 56px;
$banner-height: 40px;
$abs-footer-height-xl: 70px;
$abs-footer-height-md: 100px;
$abs-footer-height: 160px;

$button-rg-height: 40px;
$button-rg-border-size: 2px;

$letter-spacing: 0.15em;

$time: 0.5s;
$easing: cubic-bezier(0.165, 0.84, 0.44, 1);
$transition: all $time $easing;
$border-radius: 4px;

$colors: (main: #35DBAC,
  main-dark: #21BC90,
  grey-lightest: #f7f8fb,
  grey-lighter: #f2f3f4,
  grey-light: #F1F1F1,
  greyer: #d3d4d6,
  grey: #cbd5e7,
  grey-dark: #a2acbd,
  blue-light: #e7ebf3,
  blue-grey: #5d6c84,
  blue-dark: #314560,
  blue-king: #0065ff,
  blue-king-light: #d9e3f3,
  blue-king-grey: #b0caf3,
  light: #ffffff,
  dark: #111517,
  error: #FE3F52,
  transparent: transparent,
  black: #000000,
  orange: #FE8C3F,
  orange-dark: #ED6001,
  white: #ffffff);

$sizes: (xs: 1,
  sm: 1.1,
  rg: 1.2,
  md: 1.4,
  lg: 1.6,
  xl: 1.7,
  xxl: 2,
  xxxl: 3);

$xs: 0;
$sm: 420px;
$md: 768px;
$lg: 960px;
$xl: 1280px;
$xxl: 1400px;
$sm-lower: $sm - 1px;
$md-lower: $md - 1px;
$lg-lower: $lg - 1px;
$xl-lower: $xl - 1px;
$xxl-lower: $xxl - 1px;

$breakpoints: (xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl);

$shadow: 0 0 30px transparentize(#343434, 0.9);