.fullpage {
  overflow: hidden;
  min-height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-overflow-scrolling: touch;

  &-load {
    opacity: 1;
    transition: all 0s linear 0.4s, opacity 0.2s $easing 0.2s;
    z-index: 9999;

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &_menu {
    width: 250px;
  }

  &_main {
    flex: 1;
  }

  &_content {
    height: 100%;
  }

  &_desc {
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    position: absolute;
    left: 50px;
    bottom: 40px;
  }
}

.content {
  transition: $transition;
  flex: 1 0 auto;

  &.scroll {
    overflow: auto;
    height: 100%;
  }

  &-home {
    padding-top: 0;
  }

  & > section {
    padding-top: $header-height;
    @include breakpoint(lg) {
      padding-top: $header-height;
    }
  }
}
