$progress-bar-height: 6px;

.progress-top {
  position: fixed !important; // Conflicts with .tooltip
  z-index: 100;
  top: 0;
  display: inline-block;
  left: 0;
  width: 100%;
  vertical-align: middle;
  height: $progress-bar-height;
  background-color: color(grey-light);

  &__bar {
    position: fixed;
    top: 0;
    left: 0;
    transition: width 0.6s ease;
    display: inline-block;
    background: color(main);
    height: $progress-bar-height;
  }
}

.progress-sm {
  position: relative;
  width: 100px;
  vertical-align: middle;
  display: inline-block;
  height: $progress-bar-height;
  border-radius: $border-radius;
  background: color(orange-dark);

  &__bar {
    position: absolute;
    transition: width 0.6s ease;
    background: color(light);
    height: $progress-bar-height;
    border-radius: $border-radius;
  }
}
