.breadcrumb ul {
  display: inline-block;
  margin-bottom: 1em;
}

.breadcrumb li {
  white-space: nowrap;
  float: left;
}

.breadcrumb li::before {
  content: ">";
  padding: 0 1em;
}

.breadcrumb li:first-child::before {
  content: "";
  padding: 0;
}
