.intro {
  margin: 0 auto;
  position: relative;
  width: 100%;
  z-index: 1;
  font-size: 1.2em;
  line-height: 1.4em;
  padding-bottom: 50px;

  @include breakpoint-max(xl) {
    padding-bottom: 20px;
  }

  @include breakpoint-max(lg) {
    padding-top: 100px;
    margin-bottom: 0;
  }

  @include breakpoint-max(md) {
    padding-top: 50px;
    display: flex;
    flex-direction: column-reverse;
  }

  @include breakpoint-max(sm) {
    padding-top: $spacing;
    padding-bottom: $spacing;
  }

  .wrapper {

    @include breakpoint-max(md) {
      padding: 0;
      min-height: calc(100vh - #{$header-height * 1.5});
    }
  }

  .bg-01 {
    top: -$header-height;
    right: 0;
    position: absolute;
    z-index: -1;

    @include breakpoint-max(lg) {
      top: inherit;
      bottom: calc(#{$header-height} - 150px);
      text-align: right;

      img {
        width: 100%;
        max-width: 500px;
      }
    }

    @include breakpoint-max(md) {
      position: relative;
      left: 0;
      bottom: -$spacing;
    }
  }

  .title {
    margin-bottom: $spacing;

    @include breakpoint-max(lg) {
      font-size: 3em;

      .text-formatted {
        line-height: 1em;
      }
    }

    @include breakpoint-max(md) {
      margin-bottom: 0;
      padding: $spacing;
      font-size: 2em;
    }
  }

  .description {
    margin-bottom: $spacing * 3;
    width: 88%;
    text-align: justify;

    @include breakpoint-max(md) {
      padding: 0 $spacing $spacing;
    }

    @include breakpoint-max(lg) {
      margin-bottom: $spacing * 6;
    }

    @include breakpoint(md) {
      width: 67%;
    }

    @include breakpoint(lg) {
      width: 83%;
    }

    @include breakpoint(xl) {
      width: 79%;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    margin-top: $spacing * 3;

    &>button:not(:first-child) {
      margin-left: $spacing;
    }

    @include breakpoint-max(lg) {
      flex-direction: column;
      align-items: center;
      margin-top: 0;

      &>button:not(:first-child) {
        margin-top: $spacing;
        margin-left: 0;
      }
    }
  }

  .rc-pro {
    font-size: .8em;

    @include breakpoint-max(md) {
      flex-direction: column;
      justify-content: flex-end;
    }

    img {
      width: 30px;
    }
  }

}

.apps,
.benefit,
.start {
  width: 100%;
  margin-top: $spacing * 4;
  font-size: 1.2em;
  line-height: 1.4em;

  @include breakpoint-max(sm) {
    margin-top: $spacing;
  }

  .title {
    @include breakpoint-max(md) {
      padding: 0 $spacing;
    }
  }

  .description {
    @include breakpoint-max(md) {
      padding: 0 $spacing;
    }
  }

  .card {
    display: inline-block;
    max-width: 400px;
    padding: $spacing;
    margin-bottom: $spacing;
    box-shadow: 0 0 $spacing 0 rgba(0, 0, 0, 0.1);
    font-size: .8em;
    line-height: 1.4em;

    .index {
      padding: 0 $spacing 0 0;
      font-size: 1.3em;
      font-weight: 800;
      color: color(rgb(31, 28, 28));
    }
  }
}

.apps {
  position: relative;
  z-index: 0;
  overflow: hidden;
  margin: 40px auto 80px;
  padding-top: $spacing * 8;
  padding-bottom: 0;

  @include breakpoint-max(sm) {
    padding-top: $spacing;
  }

  @include breakpoint-max(md) {
    min-height: 800px;
  }

  @include breakpoint(xl) {
    padding-bottom: $spacing * 8;
  }

  .wrapper {
    @include breakpoint-max(md) {
      padding: 0;
    }
  }

  .buttons {
    padding: $spacing 0;
    margin-top: $spacing * 3;

    @include breakpoint-max(xl) {
      padding: $spacing * 2 0;
      margin-top: $spacing;
      text-align: center;
    }
  }

  .col-7-lg {
    @include breakpoint-max(md) {
      overflow: scroll;
    }
  }

  .title {
    text-align: left;

    @include breakpoint-max(xl) {
      text-align: center;
    }
  }

  .description {
    text-align: justify;

    @include breakpoint-max(xl) {
      text-align: center;
    }
  }

  .cards {
    &>div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    > :nth-child(2) {
      transform: translateY(25%);
    }

    @include breakpoint-max(xl) {
      width: 100%;
      padding: 40px;
      min-height: 300px;
      flex-wrap: nowrap;
      overflow-x: scroll;

      &>div {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      > :nth-child(2) {
        transform: translateY(0);
      }
    }

    @include breakpoint-max(lg) {
      justify-content: space-between;
    }

    .card {
      width: 220px;

      &:not(last-of-type) {
        margin-right: $spacing;
      }

      text-align: left;

      @include breakpoint-max(sm) {
        flex-direction: row;
      }

      p {
        margin-top: $spacing / 2;
      }

      img {
        height: 100px;
        margin-bottom: $spacing / 2;
      }
    }
  }
}

.benefit {
  position: relative;
  z-index: 1;
  margin-bottom: $spacing * 5;

  .bg-01,
  .bg-02 {
    position: absolute;
    z-index: -1;

    @include breakpoint-max(md) {
      position: relative;
      width: 100%;
    }
  }

  .bg-01 {
    top: 150px;
    right: 0;

    @include breakpoint-max(md) {
      top: 0;
      right: -$spacing;
    }
  }

  .bg-02 {
    bottom: 0;
    left: -$spacing;
  }

  .card {
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.10);
    border-radius: 8px;

    @include breakpoint-max(md) {
      border: 0;
      box-shadow: none;
      padding: $spacing 0 $spacing * 2 10px;
      text-align: left;
      max-width: inherit;

      &:not(:last-child) {
        border-bottom: 1px solid color(grey-light);
      }
    }
  }
}

.start {
  margin-top: 25%;

  .prices {
    margin-top: $spacing;
    justify-content: center;
  }

  .price {
    position: relative;
    z-index: 1;
    padding: $spacing;
    text-align: center;
    flex-flow: column;
    max-width: 450px;
    margin-left: $spacing;
    margin-right: $spacing;
    overflow: hidden;

    &:first-child {
      ul li:before {
        color: color(main);
      }

      @include breakpoint-max(md) {
        margin-right: 0;
        margin-left: 0;
      }
    }

    h4 {
      font-size: 1.5em;
      font-weight: 800;
    }

    h3 {
      font-size: 2em;
      margin: $spacing auto;
    }

    ul {
      text-align: left;
      flex-grow: 1;
      margin-bottom: $spacing;

      li {
        margin-left: $spacing;
        line-height: 3em;
        font-weight: 800;

        @include breakpoint-max(sm) {
          margin: 0;
        }

        &:before {
          @extend .icon;
          @extend .icon-check-circle;
          font-weight: 800;
          font-size: 1.2em;
          color: color(orange);
          margin-right: $spacing / 2;
          vertical-align: middle;
        }
      }
    }

    button {
      margin-top: $spacing;
      align-self: center;
      min-width: 200px;
    }
  }
}