$block-color: color(main);
$bg-block-color: darken(color(main-dark), 25);
$block-size: 12px;
$animation-duration: .8s;

.loader {
  display: flex;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparentize(color(light), .5);
  align-items: center;
  justify-content: center;

  &__square {
    position: relative;
    margin: 0 6px;
    width: $block-size;
    height: $block-size;
    background: $block-color;
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}n) {
        animation: loader-animation $animation-duration cubic-bezier(0.64, 0.04, 0.35, 1 ) #{$i/8 - .25}s infinite alternate;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          background: transparent;
          height: 100%;
          width: 100%;
          animation: shadow-trail #{$animation-duration * 2} cubic-bezier(0.64, 0.04, 0.35, 1 ) #{($i/8) - .125}s infinite;
        }
      }
    }

  }
}
@keyframes loader-animation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(20px);
  }
}
@keyframes shadow-trail {
  0%,
  50% {
    background: $bg-block-color;
    transform-origin: bottom;
    bottom: 100%;
    top: auto;
    transform: scaleY(0);
  }

  25%,
  75% {
    background: $bg-block-color;
    transform: scaleY(0.3);
  }

  100%,
  50.1% {
    background: $bg-block-color;
    transform: scaleY(0);
    transform-origin: top;
    bottom: auto;
    top: 100%;
  }
}
