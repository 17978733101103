$datepicker-text-color: color(blue-dark);

.react-date-picker {
  display: flex;
  position: relative;
  max-width: 250px;
  background: color(light);
  height: auto;
  border-bottom: none;

  &.bordered {
    border: 2px solid color(grey-lighter);
  }

  &--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }

  &__wrapper {
    display: flex;
    width: 100%;
  }

  &__inputGroup {
    min-width: calc(4px + (4px * 3) + 4.32em + 0.434em);
    flex-grow: 1;
    display: flex;
    padding: 0 10px;
    align-items: baseline;
    color: $datepicker-text-color;

    &__divider {
      padding: 0 1px;
    }

    &__input {
      padding: 0;
      min-width: 10px;
      height: 100%;
      text-align: center;
      font-weight: 700;
      position: relative;
      border: 0;
      outline: none;
      background: none;
      color: $datepicker-text-color;
      cursor: pointer;
      -moz-appearance: textfield;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:invalid {
        background: rgba(255, 0, 0, 0.1);
      }

      &--hadLeadingZero {
        min-width: calc(4px + 1.08em);
        margin-left: -0.54em;
        padding-left: calc(1px + 0.54em);
      }
    }
    &__year {
      min-width: 36px;
    }
  }

  &__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
    outline: none;
    color: color(grey-dark);
    transform: scale(0.8);

    &:enabled {
      cursor: pointer;

      &:focus svg g,
      &:hover svg g {
        stroke: color(main-dark);
      }
    }

    &:disabled svg g {
      stroke: #6d6d6d;
    }

    svg {
      display: inherit;

      g {
        stroke: currentColor;
      }
    }
  }

  &__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    background: color(light);
    top: 100%;
    left: 0;
    z-index: 1;

    .react-date-picker--right & {
      right: 0;
      left: auto;
    }

    &--closed {
      display: none;
    }

    &--above-label {
      bottom: 100%;
      top: auto;
    }

    .react-calendar {
      border-width: thin;
      border: 1px solid color(grey-light);
      padding-bottom: 10px;

      &__navigation {
        margin: $spacing/2 $spacing/8;

        &__arrow,
        &__label {
          border: none;
          border-radius: 0;
          font-weight: 700;
          color: dark;
          outline: none;
          cursor: pointer;
          background-color: color(light);
        }

        &__arrow {
          font-size: 1.2em;
        }
      }

      &__tile {
        font-weight: 700;
        color: color(blue-dark);
        border: none;
        border-radius: 0;
        padding: 8px;
        cursor: pointer;
        outline: none;
        background-color: color(light);

        &:not([disabled]):hover {
          color: color(main);
        }

        &--now {
          position: relative;
          font-weight: 900;

          &:after {
            content: "";
            width: 5px;
            left: 0;
            bottom: 1px;
            right: 0;
            position: absolute;
            margin: auto;
            background: color(main);
            height: 5px;
          }
        }

        &[disabled] {
          color: color(grey);
          background: color(grey-lightest);
        }
      }

      &__month-view {
        &__weekdays__weekday {
          color: color(blue-dark);
          text-align: center;
        }

        &__days__day {
          &--neighboringMonth {
            color: color(blue-grey);
          }
        }
      }

      &__tile--active {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          top: 50%;
          transform: translateY(-50%);
          width: 30px;
          height: 30px;
          background: color(main);
          mix-blend-mode: multiply;
        }
      }
    }
  }
}

.bordered .react-date-picker {
  height: 50px;
  border: 2px solid color(grey);

  &__wrapper {
    line-height: 50px;
  }
}
