.menu {
  width: 220px;
  transition: top $time $easing;
  font-weight: 700;
  position: fixed;
  top: $header-height;
  bottom: 0;
  @include breakpoint(lg) {
    top: $header-height;
  }

  &_container {
    overflow-y: auto;
    height: 100%;
    min-height: calc(100vh - #{$header-height} - #{$banner-height});
  }

  .banner ~ section & {
    top: $header-height + $banner-height;
    @include breakpoint(lg) {
      top: $header-height + $banner-height;
    }
  }

  .scrolled ~ section & {
    top: $header-height;
  }

  .scrolled.banner ~ section & {
    top: $header-height + $banner-height;
  }

  &_title {
    color: color(dark);
    margin-bottom: $spacing/2;
    padding: $spacing $spacing 0 0;
    font-size: rem(size(rg));
    font-weight: 600;

    .sub-menu & {
      color: color(dark);
    }
  }

  &_item {
    padding-top: .9em;
    padding-bottom: .9em;
    display: flex;
    align-items: center;
    padding-right: $spacing;
    color: color(dark);
    cursor: pointer;

    &--title {
      color: color(grey-dark);
    }

    &.active {
      color: color(main);
    }

    i {
      @extend .icon;
      font-weight: 900;
      font-size: 1em;
      margin-right: $spacing;
    }
  }

  &_text {
    white-space: nowrap;
    position: relative;
    z-index: 10;
    font-size: rem(size(rg));
    @include underline(0, 1px, -2px, 0);

    .pastille {
      position: absolute;
      margin: 0;
      right: 0;
      top: 50%;
      transform: translateX(200%) translateY(-50%);
    }

    .active &,
    .menu_item &:hover {
      &:after {
        width: 100%;
      }

      &--title:hover & {
        &:after {
          width: 0;
        }
      }
    }
  }
}

.menu-settings {
  height: 100%;
  background: lighten(color(grey-lightest), 1%);
  position: relative;
  width: 100%;
  border-bottom: 1px solid color(grey-light);
  @include breakpoint(md) {
    position: fixed;
    width: 220px;
    border-right: 1px solid color(grey-light);
    border-bottom: 0;
  }
}

.navigation {
  margin: $spacing 0 0 $spacing/2;
}
