.block {
  display: block;
}

.ghost {
  visibility: hidden;
  opacity: 0;
}

.fill {
  flex: 1;
  height: 100%;
  width: 100%;
}

@each $breakpoint in $breakpoints {
  $name: nth($breakpoint, 1);
  $size: nth($breakpoint, 2);
  @media only screen and (min-width: $size) {
    .fill-above-#{$name} {
      flex: 1;
      height: 100%;
      width: 100%;
    }
  }
}

.full-width {
  width: 100%;
}

.wrap {
  flex-wrap: wrap;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.align-v {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.align-h {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.align-vh {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.va-m {
  vertical-align: middle;
}

.pe-none {
  pointer-events: none;
}
