$inputWrapperHeight: 50%;
$formTopHeight: 100px;
$formBottomHeight: 120px;

[contentEditable="true"] {
  &:empty:not(:focus):before {
    content: attr(data-placeholder);
    color: color(grey-dark);
  }
}

.vform-wrapper {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: calc(100vh - #{$header-height});
  background-color: color(grey-lightest);

  .banner & {
    height: calc(100vh - #{$header-height} - #{$banner-height});
    @include breakpoint(md) {
      height: calc(100vh - #{$header-height} - #{$banner-height});
    }
  }
}

.vform {
  position: fixed;
  transition: $transition;
  width: 100%;
  top: $header-height + $formTopHeight;
  bottom: $formBottomHeight;
  height: calc(100vh - #{$header-height + $formTopHeight + $formBottomHeight});

  .banner & {
    top: $header-height + $formTopHeight + $banner-height;
    height: calc(100vh - #{$header-height + $formTopHeight + $formBottomHeight} - #{$banner-height});
  }
  @include breakpoint(lg) {
    top: #{$header-height + $formTopHeight};
    height: calc(100vh - #{$header-height + $formTopHeight + $formBottomHeight});

    .banner & {
      height: calc(100vh - #{$header-height + $formTopHeight + $formBottomHeight} - #{$banner-height});
    }
  }
  @media (max-height: 600px) {
    top: 40%;
  }
  @media (max-height: 480px) {
    top: 45%;
  }

  &_content {
    height: $inputWrapperHeight;

    &.hidden,
    &.next {
      opacity: 0;
      pointer-events: none;
      visibility: none;
    }
    @media screen and (min-height: 650px) {
      &.next {
        opacity: 0.5;
        visibility: visible;

        .input_box,
        .radios {
          display: none;
        }
      }
    }
  }

  &_bottom {
    position: absolute;
    height: calc(1% + 100px);
    bottom: 0;
  }

  &_non-editable {
    pointer-events: none;
  }
}
